<template>
  <div>
    <div class="text-center" v-animate-css="'fadeIn'">
      <div class="w-20 mx-auto mb-5 md:(w-25 h-25 mb-10)">
        <v-img :src="require(`@/assets/categories/${category.id}.svg`)"></v-img>
      </div>
      <h1 class="font-bold md:text-2xl text-gray-700">
        {{ category.name }}
      </h1>
      <v-divider v-if="divider" class="my-9"></v-divider>
      <div class="w-full mx-auto">
        <p class="text-sm !m-0 c-description" v-if="description">
          {{ category.description }}
        </p>
      </div>
    </div>
    <subMenu @onClick="onSubMenuClick"></subMenu>
  </div>
</template>
<script>
import subMenu from "@/components/subMenu.vue";
export default {
  props: {
    category: {},
    description: { default: true },
    divider: { default: true },
  },
  components: { subMenu },
  methods: {
    onSubMenuClick(item) {
      console.log("item", item);
      this.$emit("onSubMenuClick", {
        categories: this.category.id,
        ...item.value,
      });
    },
  },
};
</script>
<style scoped>
.c-description {
  white-space: pre-line;
  word-wrap: break-word;
}
</style>
