<template>
  <div class="mb-20">
    <v-row>
      <v-col cols="12" md="6">
        <div
          class="bg-amber-400 h-100 rounded-xl text-center p-10 flex flex-col justify-between"
        >
          <div>
            <h2 class="text-3xl font-bold mb-6">獲得更多 COOTL 文章</h2>
            <p>{{ subcribeMsg }}</p>
          </div>
          <div>
            <v-text-field label="Your E-mail" v-model="email"> </v-text-field>
            <v-btn rounded color="black" dark width="120" @click="onSubmit"
              >送出</v-btn
            >
          </div>
        </div>
      </v-col>
      <v-col cols="12" md="6">
        <div
          class="border-2 h-100 rounded-xl text-center p-10 flex flex-col justify-between"
        >
          <div>
            <h2 class="text-3xl font-bold mb-6">分享您的想法</h2>
            <p>從我們的COOTL工作小組接收有關計劃、項目和進展的獨家更新內容。</p>
          </div>
          <div>
            <!-- <v-text-field label="Your E-mail"> </v-text-field> -->
            <v-btn
              rounded
              color="black"
              dark
              width="120"
              href="mailto:cootl_admin@protonmail.com"
              >更多內容</v-btn
            >
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { submitEmail } from "@/api/index";
const methods = {
  async onSubmit() {
    const formData = new FormData();
    formData.append("your-email", this.email);
    try {
      const { data } = await submitEmail(formData);
      if (data.status != "mail_sent") throw Error();
      this.subcribeMsg = "我們已收到您的訂閱，謝謝您";
      console.log(data);
    } catch (error) {
      this.subcribeMsg = "寄送失敗，請檢查您的email是否正確!";
    }
  },
};
export default {
  methods,
  data() {
    return {
      email: "",
      subcribeMsg:
        "從我們的COOTL工作小組接收有關計劃、項目和進展的獨家更新內容。",
    };
  },
};
</script>
