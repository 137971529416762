<template>
  <div>
    <div class="py-1 md:py-4 px-3" v-if="$route.name != 'Post'">
      <div class="visible md:invisible absolute top-10 left-10">
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        <v-navigation-drawer v-model="drawer" fixed bottom>
          <v-list>
            <v-list-item-group
              v-model="group"
              active-class="deep-purple--text text--accent-4"
            >
              <div v-for="(item, index) in categories" :key="index">
                <v-list-item
                  class="text-center border-b-1 w-1/2 mx-auto"
                  :to="{
                    name: 'Catagories',
                    params: { name: item.name, id: item.id, page: 1 },
                  }"
                >
                  <v-list-item-title> {{ item.name }} </v-list-item-title>
                </v-list-item>
              </div>
              <v-list-item
                class="text-center"
                :to="{
                  name: 'About',
                }"
              >
                <v-list-item-title>關於我們</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-navigation-drawer>
      </div>

      <div class="w-full">
        <!-- <div></div> -->
        <!-- <v-icon large color="gray darken-2"> mdi-menu </v-icon> -->
        <div
          class="text-center mb-5 mx-auto w-full"
          @click="
            $router.push({ name: 'Home' }).catch((err) => {
              err;
            })
          "
        >
          <img :src="logo" class="w-auto h-26 cursor-pointer mx-auto" alt="" />
        </div>

        <div class="md:(absolute top-10 right-20)">
          <searchBar></searchBar>
        </div>
      </div>
      <v-divider class="invisible md:visible"></v-divider>
      <div class="w-10/12 mx-auto flex justify-between pt-3 <md:hidden">
        <div v-for="(item, index) in categories" :key="index">
          <router-link
            class="!text-gray-800"
            :to="{
              name: 'Catagories',
              params: { name: item.name, id: item.id, page: 1 },
            }"
          >
            {{ item.name }}
          </router-link>
        </div>
        <router-link
          class="!text-gray-800"
          :to="{
            name: 'About',
          }"
        >
          關於我們
        </router-link>
      </div>
    </div>

    <!-- dark mode -->
    <div v-else class="absolute z-50 w-full">
      <div class="py-4 px-3">
        <div class="visible md:invisible absolute top-10 left-10">
          <!-- <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon> -->
          <v-btn icon @click.stop="drawer = !drawer" dark>
            <v-icon>mdi-menu</v-icon>
          </v-btn>

          <v-navigation-drawer
            v-model="drawer"
            fixed
            bottom
            app
            disable-route-watcher
          >
            <v-list>
              <v-list-item-group
                active-class="deep-purple--text text--accent-4"
              >
                <div v-for="(item, index) in categories" :key="index">
                  <v-list-item
                    exact
                    class="text-center"
                    :to="{
                      name: 'Catagories',
                      params: { name: item.name, id: item.id, page: 1 },
                    }"
                  >
                    <v-list-item-title> {{ item.name }} </v-list-item-title>
                  </v-list-item>
                </div>
                <v-list-item
                  class="text-center"
                  :to="{
                    name: 'About',
                  }"
                >
                  <v-list-item-title>關於我們</v-list-item-title>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-navigation-drawer>
        </div>

        <div class="w-full flex flex-auto justify-between">
          <div></div>
          <!-- <v-icon large color="gray darken-2"> mdi-menu </v-icon> -->
          <div
            class="text-center mb-5"
            @click="
              $router.push({ name: 'Home' }).catch((err) => {
                err;
              })
            "
          >
            <img :src="logoWhite" class="w-auto h-26 cursor-pointer" alt="" />
          </div>

          <div>
            <div class="absolute right-40">
              <!-- <searchBar></searchBar> -->
            </div>
            <!-- <v-icon large color="gray darken-2"> mdi-magnify </v-icon> -->
          </div>
        </div>
        <v-divider class="invisible md:visible dark" dark></v-divider>
        <div
          class="w-10/12 mx-auto flex justify-between pt-3 invisible md:visible"
        >
          <div v-for="(item, index) in categories" :key="index">
            <router-link
              class="!text-gray-800 !text-white"
              :to="{
                name: 'Catagories',
                params: { name: item.name, id: item.id },
              }"
            >
              {{ item.name }}
            </router-link>
          </div>
          <router-link
            class="!text-gray-800 !text-white"
            :to="{
              name: 'About',
            }"
          >
            關於我們
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import logo from "@/assets/COOTL_logo_Color.svg";
import logoWhite from "@/assets/COOTL_logo_White.svg";
import searchBar from "./searchBar.vue";
import { getCategories } from "@/api/index";
import { mapGetters } from "vuex";
export default {
  components: { searchBar },
  data() {
    return {
      logo,
      logoWhite,
      drawer: false,
      group: null,
    };
  },
  computed: {
    ...mapGetters(["categories"]),
  },
  async created() {
    console.log("categories", this.categories);
  },
};
</script>
<style scoped>
.dark {
  border: 0.3px rgba(255, 255, 255, 0.702) solid;
}
</style>
