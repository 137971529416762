<template>
  <div class="text-sm text-gray-500 leading-loose">
    <v-divider class="my-16"></v-divider>
    <div class="py-10 pb-0">
      <v-row>
        <v-col cols="12" md="6" class="p-1">
          <v-img :src="logo" class="w-2/3 mb-5" contain></v-img>
          <p>
            Save the truth for the next generations in Hong Kong. Unite
            Hongkongers abroad.Uphold universal values and global perspective.
          </p>
          <v-divider class="mb-5"></v-divider>
          <p>
            為留港的下一代保存真相，為離散的港人凝聚力量，堅持普世價值，貫通全球視野。
          </p>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="12" md="4" class="hidden md:block">
          <v-row>
            <v-col cols="7" md="6">
              <div class="">
                <li
                  v-for="(item, index) in categories"
                  :key="index"
                  class="list-none mb-5 cursor-pointer"
                >
                  <router-link
                    class="!text-gray-500 list-none mb-5 cursor-pointer"
                    :to="{
                      name: 'Catagories',
                      params: { name: item.name, id: item.id, page: 1 },
                    }"
                  >
                    {{ item.name }}
                  </router-link>
                </li>
              </div>
            </v-col>

            <v-col>
              <li
                v-for="(item, index) in menu"
                :key="index"
                class="list-none mb-5 cursor-pointer"
              >
                <router-link
                  class="!text-gray-500 list-none mb-5 cursor-pointer"
                  :to="item.link"
                >
                  {{ item.title }}
                </router-link>
              </li>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <div class="b-1">
      <p class="text-sm">
        @2022 COOTL - Citizens of Our Time Learning Hub all rights reserved.
      </p>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      logo: require("@/assets/COOTL_logotype_bk.svg"),
      menu: [
        { title: "首頁", link: { name: "Home" } },
        { title: "關於我們", link: { name: "About" } },
        // { title: "議題", link: {} },
        // { title: "專題", link: {} },
        // { title: "評論", link: {} },
      ],
    };
  },
  computed: {
    ...mapGetters(["categories"]),
  },
};
</script>
