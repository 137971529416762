import { render, staticRenderFns } from "./flipCard.vue?vue&type=template&id=bbc3456a&scoped=true&"
var script = {}
import style0 from "./flipCard.vue?vue&type=style&index=0&id=bbc3456a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bbc3456a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VSheet})
