// const v2 = "https://lab.apdc.com.tw/wp-json/wp/v2";
const base = "https://wordpress-775496-2637981.cloudwaysapps.com/wp-json/wp/v2";
const baseOrigin = "https://wordpress-775496-2637981.cloudwaysapps.com/wp-json";
const orz = `${baseOrigin}/orz`;
const contactForm =
  "https://wordpress-775496-2637981.cloudwaysapps.com/wp-json/contact-form-7/v1/contact-forms/282/feedback";

import axios from "axios";

export const getTags = () => axios.get(`${base}/tags`, { params: {} });
export const getTag = (id) => axios.get(`${base}/tags/${id}`, { params: {} });
export const getCategories = ({ exclude, include, parent }) =>
  axios.get(`${base}/categories`, { params: { exclude, include, parent } });

export const getPosts = ({ categories, tags, per_page, sticky, page }) =>
  axios.get(`${base}/posts`, {
    params: {
      categories,
      tags,
      _embed: true,
      per_page,
      sticky,
      page,
    },
  });

export const getPost = (id, password = null) =>
  axios.get(`${base}/posts/${id}`, { params: { _embed: true, password } });

export const getProtectPost = (id, password) =>
  axios.get(`${base}/posts/${id}`, { params: { _embed: true, password } });

export const getAbout = () =>
  axios.get(`${base}/posts`, { params: { categories: 13, _embed: true } });

export const search = ({ search }) =>
  axios.get(`${base}/posts`, { params: { search, _embed: true } });

export const submitEmail = (data) => axios.post(`${contactForm}`, data);

export const getRelatePost = (postId) =>
  axios.get(`${baseOrigin}/yarpp/v1/related/${postId}`, {
    params: { _embed: true, limit: 3 },
  });

export const addViewCounts = (id) =>
  axios.post(`${orz}/viewcount/post/${id}`, { params: {} });
