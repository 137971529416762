var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.$route.name != 'Post')?_c('div',{staticClass:"py-1 md:py-4 px-3"},[_c('div',{staticClass:"visible md:invisible absolute top-10 left-10"},[_c('v-app-bar-nav-icon',{on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}}),_c('v-navigation-drawer',{attrs:{"fixed":"","bottom":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list',[_c('v-list-item-group',{attrs:{"active-class":"deep-purple--text text--accent-4"},model:{value:(_vm.group),callback:function ($$v) {_vm.group=$$v},expression:"group"}},[_vm._l((_vm.categories),function(item,index){return _c('div',{key:index},[_c('v-list-item',{staticClass:"text-center border-b-1 w-1/2 mx-auto",attrs:{"to":{
                  name: 'Catagories',
                  params: { name: item.name, id: item.id, page: 1 },
                }}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.name)+" ")])],1)],1)}),_c('v-list-item',{staticClass:"text-center",attrs:{"to":{
                name: 'About',
              }}},[_c('v-list-item-title',[_vm._v("關於我們")])],1)],2)],1)],1)],1),_c('div',{staticClass:"w-full"},[_c('div',{staticClass:"text-center mb-5 mx-auto w-full",on:{"click":function($event){_vm.$router.push({ name: 'Home' }).catch(function (err) {
            err;
          })}}},[_c('img',{staticClass:"w-auto h-26 cursor-pointer mx-auto",attrs:{"src":_vm.logo,"alt":""}})]),_c('div',{staticClass:"md:absolute md:top-10 md:right-20"},[_c('searchBar')],1)]),_c('v-divider',{staticClass:"invisible md:visible"}),_c('div',{staticClass:"w-10/12 mx-auto flex justify-between pt-3 <md:hidden"},[_vm._l((_vm.categories),function(item,index){return _c('div',{key:index},[_c('router-link',{staticClass:"!text-gray-800",attrs:{"to":{
            name: 'Catagories',
            params: { name: item.name, id: item.id, page: 1 },
          }}},[_vm._v(" "+_vm._s(item.name)+" ")])],1)}),_c('router-link',{staticClass:"!text-gray-800",attrs:{"to":{
          name: 'About',
        }}},[_vm._v(" 關於我們 ")])],2)],1):_c('div',{staticClass:"absolute z-50 w-full"},[_c('div',{staticClass:"py-4 px-3"},[_c('div',{staticClass:"visible md:invisible absolute top-10 left-10"},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}},[_c('v-icon',[_vm._v("mdi-menu")])],1),_c('v-navigation-drawer',{attrs:{"fixed":"","bottom":"","app":"","disable-route-watcher":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list',[_c('v-list-item-group',{attrs:{"active-class":"deep-purple--text text--accent-4"}},[_vm._l((_vm.categories),function(item,index){return _c('div',{key:index},[_c('v-list-item',{staticClass:"text-center",attrs:{"exact":"","to":{
                    name: 'Catagories',
                    params: { name: item.name, id: item.id, page: 1 },
                  }}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.name)+" ")])],1)],1)}),_c('v-list-item',{staticClass:"text-center",attrs:{"to":{
                  name: 'About',
                }}},[_c('v-list-item-title',[_vm._v("關於我們")])],1)],2)],1)],1)],1),_c('div',{staticClass:"w-full flex flex-auto justify-between"},[_c('div'),_c('div',{staticClass:"text-center mb-5",on:{"click":function($event){_vm.$router.push({ name: 'Home' }).catch(function (err) {
              err;
            })}}},[_c('img',{staticClass:"w-auto h-26 cursor-pointer",attrs:{"src":_vm.logoWhite,"alt":""}})]),_vm._m(0)]),_c('v-divider',{staticClass:"invisible md:visible dark",attrs:{"dark":""}}),_c('div',{staticClass:"w-10/12 mx-auto flex justify-between pt-3 invisible md:visible"},[_vm._l((_vm.categories),function(item,index){return _c('div',{key:index},[_c('router-link',{staticClass:"!text-gray-800 !text-white",attrs:{"to":{
              name: 'Catagories',
              params: { name: item.name, id: item.id },
            }}},[_vm._v(" "+_vm._s(item.name)+" ")])],1)}),_c('router-link',{staticClass:"!text-gray-800 !text-white",attrs:{"to":{
            name: 'About',
          }}},[_vm._v(" 關於我們 ")])],2)],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"absolute right-40"})])}]

export { render, staticRenderFns }