<template>
  <v-app class="c-bg">
    <navbar></navbar>
    <v-main>
      <transition>
        <router-view />
      </transition>
    </v-main>
    <v-container>
      <mainFooter></mainFooter>
    </v-container>
  </v-app>
</template>

<script>
import navbar from "@/components/navbar.vue";
import mainFooter from "@/components/footer.vue";
import { mapActions } from "vuex";
export default {
  components: { navbar, mainFooter },
  name: "App",
  methods: {
    ...mapActions(["getTags", "getCategories"]),
  },
  async created() {
    // await this.getTags();
    await this.getCategories();
  },
  data: () => ({
    //
  }),
};
</script>
<style scoped>
.c-bg {
  background-color: #fffcf6 !important;
}
</style>
<style>
.theme--light.v-card {
  background-color: #fffcf6 !important;
}
</style>
