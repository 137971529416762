<template>
  <div class="py-5 w-full px-2">
    <v-row>
      <v-col cols="12" lg="8" md="8">
        <div
          class="pb-3"
          v-animate-css="'fadeIn'"
          @click="onPostClick(headerPost['L01'])"
        >
          <v-img
            v-if="headerPost['L01']"
            :src="headerPost['L01']._embedded | featureImg"
            gradient="to top, rgba(0,0,0,.35),rgba(0,0,0,.1)"
            class="rounded-lg"
            height="714px"
          >
            <div class="flex flex-col justify-between text-white">
              <div></div>
              <div>
                <div class="p-5 text-shadow-lg">
                  <div class="text-md pb-3">
                    {{ headerPost["L01"]._embedded | categoryFilter }}
                  </div>
                  <h2 class="text-2xl mb-3 font-bold">
                    {{ headerPost["L01"].title.rendered }}
                  </h2>
                </div>
              </div>
            </div>
          </v-img>
        </div>
      </v-col>
      <v-col cols="12" lg="4" md="4" v-animate-css="'fadeIn'">
        <div class="flex flex-col justify-between">
          <div v-for="(item, index) in headerPost" :key="index">
            <div v-if="index != 'L01' && item" class="pb-3">
              <flipCard class="border-0">
                <template v-slot:front>
                  <v-img
                    v-if="item"
                    :src="item['_embedded'] | featureImg"
                    gradient="to top, rgba(0,0,0,.35),rgba(0,0,0,.1)"
                    class="rounded-lg !border-0"
                    height="100%"
                    @click="onPostClick(item)"
                  >
                    <div class="h-full flex flex-col justify-between">
                      <div></div>
                      <div class="p-5 text-white text-shadow-lg">
                        <div class="text-md pb-3">
                          {{ item._embedded | categoryFilter }}
                        </div>
                        <h2 class="text-2xl mb-3 font-bold">
                          {{ item.title.rendered }}
                        </h2>
                      </div>
                    </div>
                  </v-img>
                </template>
                <template v-slot:back>
                  <div class="p-5" @click="onPostClick(item)">
                    <div class="text-sm">
                      <div v-html="item.excerpt.rendered"></div>
                    </div>
                  </div>
                </template>
              </flipCard>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { getCategories, getPosts } from "@/api/index";
import flipCard from "./flipCard.vue";
import imgPlaceholder from "@/assets/imgPlaceholder.jpg";
const methods = {
  async init() {
    const { data } = await getCategories({ parent: 18 });
    this.headerCategories = data;
    await this.handleHeader();
  },

  async handleHeader(categories) {
    const headerCategories = this.headerCategories.map(async (item) => {
      const { data } = await getPosts({ categories: item.id });
      this.headerPost[item.name] = data[this.getRandom(data.length - 1)];
    });
    console.log("headerPost", this.headerPost);
  },

  getRandom(x) {
    return Math.floor(Math.random() * x) + 0;
  },

  onPostClick(item) {
    console.log(item.id);
    this.$router.push({ name: "Post", params: { id: item.id } });
  },
};
export default {
  methods,
  components: {
    flipCard,
  },
  data() {
    return {
      url: "https://wordpress-775496-2637981.cloudwaysapps.com/wp-content/uploads/573f75b1-04e9-331b-bd1b-10f2eb749eca.jpg",
      headerCategories: [],
      headerPost: {
        L01: null,
        R01: null,
        R02: null,
        R03: null,
      },
    };
  },
  async created() {
    this.init();
  },
  filters: {
    featureImg(_embedded) {
      if (_embedded["wp:featuredmedia"]) {
        if (!_embedded["wp:featuredmedia"][0].source_url) return imgPlaceholder;
        return _embedded["wp:featuredmedia"][0].source_url;
      } else {
        return imgPlaceholder;
      }
    },
    categoryFilter(_embedded) {
      // if (_embedded["wp:term"][0][0].name.match(/'R01'/g))
      return _embedded["wp:term"][0][0].name;
    },
  },
};
</script>
<style scoped>
.c-header {
  height: 60vh;
}
</style>
