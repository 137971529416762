import Vue from "vue";
import Vuex from "vuex";
import { getTags, getCategories } from "@/api/index";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    tags: [],
    categories: [],
    subMenuItems: [
      { title: "熱門", value: { sticky: true } },
      { title: "最新" },
      { title: "推薦", value: { tags: 33 } },
    ],
  },
  getters: {
    tags(state) {
      return state.tags;
    },
    categories(state) {
      return state.categories;
    },
    subMenuItems(state) {
      return state.subMenuItems;
    },
  },
  mutations: {
    setTags(state, payload) {
      state.tags = payload;
    },
    setCategories(state, payload) {
      state.categories = payload;
    },
  },
  actions: {
    async getCategories({ commit }) {
      const include = [3, 7, 8, 5, 6, 4];
      const exclude = ["9", "1", "46"];
      const { data } = await getCategories({ include });
      console.log("categories", data);
      commit("setCategories", data);
    },
    async getTags({ commit }) {
      const { data } = await getTags();
      commit("setTags", data);
    },
  },
  modules: {},
});
