<template>
  <div>
    <div>
      <v-text-field
        label="Search"
        placeholder=""
        filled
        rounded
        dense
        prepend-inner-icon="mdi-magnify"
        v-on:keyup.enter="onSearch()"
        clear-icon="mdi-close-circle"
        clearable
        v-model="keyword"
      ></v-text-field>
      <!-- <input
        type="text"
        placeholder=" "
        v-on:keyup.enter="onSearch()"
        v-model="keyword"
      />
      <button type="reset"></button> -->
    </div>
  </div>
</template>

<script>
import { search } from "@/api/index";
const methods = {
  onSearch() {
    console.log(this.keyword);
    this.$router.push({ name: "Search", params: { keyword: this.keyword } });
    this.$emit("search", this.keyword);
  },
};
export default {
  methods,
  data() {
    return {
      keyword: "",
    };
  },
};
</script>

<style lang="scss" scoped>
$search-time: 800ms;
$reset-time: 150ms;

@import url("https://fonts.googleapis.com/css?family=Raleway:400,400i,700");

html,
body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}

body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  overflow: hidden;
}

.search-box {
  border: solid 3px rgb(86, 86, 86);
  display: inline-block;
  position: relative;
  border-radius: 50px;
  input[type="text"] {
    font-family: Raleway, sans-serif;
    font-size: 20px;
    font-weight: bold;
    width: 50px;
    height: 50px;
    padding: 5px 40px 5px 10px;
    border: none;
    box-sizing: border-box;
    border-radius: 50px;
    transition: width $search-time cubic-bezier(0.68, -0.55, 0.27, 1.55)
      $reset-time;
    &:focus {
      outline: none;
    }
    &:focus,
    &:not(:placeholder-shown) {
      width: 300px;
      transition: width $search-time cubic-bezier(0.68, -0.55, 0.27, 1.55);
      + button[type="reset"] {
        bottom: 13px;
        right: 10px;
        transition: bottom $reset-time ease-out $search-time,
          right $reset-time ease-out $search-time;
      }
      + button[type="reset"]:after {
        top: 0;
        right: 10px;
        opacity: 1;
        transition: top $reset-time ease-out ($search-time + $reset-time),
          right $reset-time ease-out ($search-time + $reset-time),
          opacity $reset-time ease ($search-time + $reset-time);
      }
    }
  }
  button[type="reset"] {
    background-color: transparent;
    width: 25px;
    height: 25px;
    border: 0;
    padding: 0;
    outline: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: -13px;
    right: -15px;
    transition: bottom $reset-time ease-out $reset-time,
      right $reset-time ease-out $reset-time;
    &:before,
    &:after {
      content: "";
      height: 25px;
      border-left: solid 3px rgb(86, 86, 86);
      position: absolute;
      transform: rotate(-45deg);
    }
    &:after {
      transform: rotate(45deg);
      opacity: 0;
      top: -20px;
      right: -10px;
      transition: top $reset-time ease-out, right $reset-time ease-out,
        opacity $reset-time ease-out;
    }
  }
}
</style>
