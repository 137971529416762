<template>
  <div>
    <div v-if="posts.length">
      <v-divider class="mb-16 mt-8"></v-divider>

      <categoryTitle
        :category="category"
        :description="false"
        :divider="false"
        @onSubMenuClick="onSubMenuClick"
      ></categoryTitle>
      <v-row>
        <v-col sm="12" md="4" v-for="(item, index) in posts" :key="index">
          <v-card elevation="0" @click="onPostClick(item)">
            <v-img :src="item._embedded | featureImg" class="mb-3 !rounded-lg">
            </v-img>
            <v-card-text class="px-0">
              <!-- <v-card-title> </v-card-title> -->
              <h2 class="font-bold text-xl mt-4 mb-5">
                {{ item.title.rendered }}
              </h2>
              <!-- <div class="text-sm mb-3">
                {{ item.date | moment("MMMM DD, YYYY") }}
              </div> -->
              <p
                v-if="item.excerpt.rendered"
                v-html="item.excerpt.rendered"
              ></p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
// import { getPosts } from "@/api/index";
import { getPosts, getMostViewPosts } from "@/api/post";
import subMenu from "@/components/subMenu.vue";
import categoryTitle from "@/components/categoryTitle.vue";
import imgPlaceholder from "@/assets/imgPlaceholder.jpg";
const methods = {
  async init() {
    const { data } = await getPosts({
      categories: this.category.id,
      per_page: 3,
      tags: [],
    });
    this.posts = data;
    console.log(this.category.id);
    console.log("post->", this.posts);
  },
  async onSubMenuClick(condition) {
    condition.per_page = 3;
    if (condition.sticky) {
      const ids = await this.getMostViewsIDs(condition.categories);
      console.log(ids);
      const data = await this.getMostViewsPosts(ids);
      console.log(data);
      if (data.length) return (this.posts = data);
    } else {
      const { data } = await getPosts(condition);
      if (data.length) return (this.posts = data);
    }
    // const { data } = await getPosts(condition);
    // if (data.length) return (this.posts = data);
  },
  async getMostViewsIDs(category_id) {
    const { data } = await getMostViewPosts(category_id);
    return data;
  },
  async getMostViewsPosts(ids) {
    const { data } = await getPosts({ include: ids });
    return data;
  },
  redirect(id) {
    console.log("go");
    this.$router.push({ name: "Protect", params: { id } });
  },
  onPostClick(item) {
    console.log(item.id);
    this.$router.push({ name: "Post", params: { id: item.id } });
  },
};
export default {
  components: { subMenu, categoryTitle },
  data() {
    return {
      posts: [],
    };
  },

  async created() {
    await this.init();
  },
  methods,
  filters: {
    featureImg(_embedded) {
      if (_embedded["wp:featuredmedia"]) {
        if (!_embedded["wp:featuredmedia"][0].source_url) return imgPlaceholder;
        return _embedded["wp:featuredmedia"][0].source_url;
      } else {
        return imgPlaceholder;
      }
    },
    tagsFilter(_embedded) {
      return _embedded["wp:term"][1];
    },
  },
  props: ["category"],
};
</script>
