<template>
  <div>
    <div class="">
      <cheader></cheader>
    </div>
    <v-container>
      <div v-for="(item, index) in categories" :key="index">
        <csection :category="item"></csection>
      </div>

      <popular></popular>

      <contact></contact>
      <cfooter></cfooter>
      <!-- <mainFooter></mainFooter> -->
    </v-container>
  </div>
</template>

<script>
import { getPosts } from "@/api/post";
import { mapGetters } from "vuex";
import cheader from "./components/cheader.vue";
import csection from "./components/csection.vue";
import popular from "./components/popular.vue";
import contact from "./components/contact.vue";
import footer from "./components/footer.vue";
import mainFooter from "@/components/footer.vue";
const methods = {
  async init() {
    const { data } = await getPosts();
    console.log("post", data);
    this.posts = data;
  },
};
export default {
  name: "Home",
  components: {
    cheader,
    csection,
    popular,
    contact,
    cfooter: footer,
    mainFooter,
  },
  filters: {
    featureImg(_embedded) {
      return _embedded["wp:featuredmedia"][0].source_url;
    },
    categoryFilter(_embedded) {
      return _embedded["wp:term"][0][0].name;
    },
  },
  data() {
    return {
      posts: [],
      carousel: 0,
    };
  },
  computed: {
    ...mapGetters(["categories"]),
  },

  methods,
  async created() {
    window.scrollTo(0, 0);
    await this.init();
  },
};
</script>
<style>
figure img {
  max-width: 80% !important;
}
</style>
