<template>
  <div>
    <div class="flex justify-between w-3/5 md:w-1/3 mx-auto my-12 md:my-17">
      <div
        v-for="(item, index) in subMenuItems"
        :key="index"
        v-animate-css="'fadeIn'"
        class="font-bold cursor-pointer py-1 px-3 tag"
        :class="active(index)"
        @click="
          onSubMenuClick(item);
          select = index;
        "
      >
        {{ item.title }}
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  methods: {
    onSubMenuClick(item) {
      console.log("submenu", item);
      this.$emit("onClick", item);
    },
    active(index) {
      if (index == this.select) return "active";
    },
  },
  data() {
    return {
      select: 1,
    };
  },
  computed: {
    ...mapGetters(["subMenuItems"]),
  },
};
</script>
<style scoped>
.active {
  border: 1px solid rgba(0, 0, 0, 1) !important;
  transition: border 1s ease;
  transition: opacity 1s ease;
  opacity: 1 !important;
}
.tag {
  border: 1px solid #00000000;
  border-radius: 1rem;
  opacity: 0.4;
}
</style>
