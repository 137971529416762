<template>
  <div>
    <div class="mb-20" v-if="posts.length">
      <v-divider class="mb-20"></v-divider>
      <div class="text-center">
        <h1 class="font-bold text-2xl">熱門類別</h1>
        <p>Hot news</p>
      </div>

      <VueSlickCarousel
        :arrows="true"
        :centerMode="true"
        v-bind="slickOptions"
        @breakpoint="handleBreakpoint"
        v-if="loaded"
      >
        <div v-for="(item, index) in posts" :key="index" class="px-2 md:px-0">
          <div class="h-100 w-full md:w-4/5 bg-amber-400 rounded-xl p-10">
            <v-row>
              <v-col cols="5" md="7">
                <div class="h-xs flex flex-col justify-between">
                  <div>
                    <h2 class="text-5xl mb-3">{{ index + 1 }}</h2>
                    <p>{{ item._embedded["wp:term"][0][0].name }}</p>
                  </div>

                  <div>
                    <h2 class="text-xl mb-3">{{ item.title.rendered }}</h2>
                    <p
                      class="text-sm hidden md:block"
                      v-html="item.excerpt.rendered"
                    ></p>
                  </div>

                  <div>
                    <v-btn outlined large @click="onPostClick(item)"
                      >前往閱讀</v-btn
                    >
                  </div>
                </div>
              </v-col>
              <v-col cols="7" md="5">
                <div>
                  <v-img
                    v-if="item._embedded['wp:featuredmedia'][0]"
                    :src="item._embedded | featureImg"
                    class="mb-7 rounded-xl"
                    height="320"
                  >
                  </v-img>
                  <v-img
                    v-else
                    :src="require(`@/assets/imgPlaceholder.jpg`)"
                    height="320"
                    class="rounded-xl"
                  ></v-img>
                </div>
              </v-col>
            </v-row>
          </div>
        </div>
      </VueSlickCarousel>
    </div>
  </div>
</template>
<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import { getPosts } from "@/api/index";
export default {
  components: { VueSlickCarousel },
  data() {
    return {
      posts: [],
      loaded: false,
      slickOptions: {
        dots: true,

        responsive: [
          {
            breakpoint: 640,
            settings: {
              dots: true,
              centerMode: false,
            },
          },
        ],
      },
    };
  },
  methods: {
    async init() {
      const { data } = await getPosts({ sticky: true });
      this.posts = data;

      await console.log("stycky", this.posts);
      this.loaded = true;
    },

    onPostClick(item) {
      console.log(item.id);
      this.$router.push({ name: "Post", params: { id: item.id } });
    },

    handleBreakpoint(event, slick, breakpoint) {
      console.log("handleBreakpoint", event, slick, breakpoint);
    },
  },
  async created() {
    await this.init();
  },
  filters: {
    featureImg(_embedded) {
      const placeHolder = require(`@/assets/imgPlaceholder.jpg`);
      if (_embedded["wp:featuredmedia"][0].hasOwnProperty("source_url"))
        return _embedded["wp:featuredmedia"][0].source_url;
      return placeHolder;
    },
    tagsFilter(_embedded) {
      return _embedded["wp:term"][1];
    },
  },
};
</script>
