import axios from "axios";

// const v2 = "https://lab.apdc.com.tw/wp-json/wp/v2";
const base = "https://wordpress-775496-2637981.cloudwaysapps.com/wp-json/wp/v2";
const baseOrigin = "https://wordpress-775496-2637981.cloudwaysapps.com/wp-json";
const orz = `${baseOrigin}/orz`;

// export const getPosts = () => axios.get(`${base}/posts?_embed`);
export const getPosts = ({
  categories,
  tags,
  per_page,
  sticky,
  page,
  include,
}) =>
  axios.get(`${base}/posts`, {
    params: {
      categories,
      tags,
      _embed: true,
      per_page,
      sticky,
      page,
      include,
    },
  });
export const getPost = (id) =>
  axios.get(`${base}/posts/${id}`, { params: { _embed: true } });
export const addViewCounts = (id) =>
  axios.post(`${orz}/viewcount/post/${id}`, { params: {} });
export const getMostViewPosts = (category_id) =>
  axios.get(`${base}/mostview/${category_id}`, { params: { _embed: true } });
